<template>
  <div>
    <div class="message-box">
      <!-- <div class="title">基本信息</div> -->
      <div class="message-box-box">
        <div class="message-box-left">
          <div class="title">
            基本信息
            <span class="hint" v-if="$route.query.steured == 'false'"
              >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
            >
          </div>
          <div class="center-box">
            <div v-for="(item, index) in basicInfo" :key="index">
              <div v-if="item.key == 'goodsType'">
                <span>{{ item.name }}</span>
                <span v-if="basicinfoData[item.key] == 0" class="noend"
                  >普药</span
                >
                <span v-if="basicinfoData[item.key] == 1" class="noend"
                  >中药</span
                >
                <span v-if="basicinfoData[item.key] == 2" class="noend"
                  >非药品</span
                >
              </div>
              <div v-else-if="item.key == 'categoryArr'" class="categoryArr">
                <span class="categoryArr-title">{{ item.name }}</span>
                <div >
                  <div v-if="basicinfoData.category" class="categoryArr-box" @mouseover="showCategoryArr=true"
                      @mouseleave="showCategoryArr=false">
                    <span
                      class="el-dropdown-link"
                      v-if="basicinfoData.category.length > 0"
                      
                    >
                      {{ basicinfoData.categoryArr[0]
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <div class="lang-select-dropdown" v-if="showCategoryArr">
                      <div
                        v-for="(item, index) in basicinfoData.categoryArr"
                        :key="index"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                  <!-- <el-dropdown v-if="basicinfoData.category" click="el-dropdown-box">
                    <span class="el-dropdown-link" v-if="basicinfoData.category.length>0">
                      {{ basicinfoData.categoryArr[0]
                      }}<i class="el-icon-arrow-down el-icon--right" ></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="lang-select-dropdown">
                      <el-dropdown-item
                        v-for="(item, index) in basicinfoData.categoryArr"
                        :key="index"
                        >{{ item }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown> -->
                  <!-- <span
                    v-for="(v, i) in basicinfoData.category"
                    :key="i"
                    class="categoryArr-name"
                  >
                    {{ v }}
                  </span> -->
                </div>
              </div>
              <div v-else-if="item.key == 'uniqueId'" class="categoryArr"><span>{{ item.name }}</span>{{ basicinfoData[item.key] || "/" }}</div>
              <div v-else>
                <span>{{ item.name }}</span>
                {{ basicinfoData[item.key] || "/" }}
              </div>
            </div>
          </div>
        </div>
        <div class="message-box-right">
          <div v-if="!chagepic">
            <div
              class="goods-img-box"
              v-for="(item, index) in picture"
              :key="index + ''"
            >
              <div class="title">
                {{ item.name
                }}<span class="hint" v-if="$route.query.steured == 'false'"
                  >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
                >
              </div>
              <div class="goods-img">
                <div
                  v-for="(item, index) in imgDate"
                  :key="index"
                  style="display: inline-block"
                  class="rela marle_10"
                >
                  <el-image
                    style="width: 100px; height: 100px; border-radius: 10px"
                    :src="item.url"
                    :preview-src-list="[item.url]"
                  >
                  </el-image>
                  <!-- <div class="absoimg" style="width: 100px">点击放大</div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 商品图片 -->

          <!-- 厂家信息 -->
          <div class="vender-message-box">
            <div class="title">
              厂家信息<span class="hint" v-if="$route.query.steured == 'false'"
                >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
              >
            </div>
            <div class="vender-message">
              <div
                v-for="(item, index) in manufacturerInfo"
                :key="index"
                :class="
                  item.key == 'manufacturerreferred' ||
                  item.key == 'listinglicenseholder'
                    ? 'half'
                    : 'all'
                "
              >
                <div>
                  <span :class="index % 2 == 0 ? 'brief' : ''">{{
                    item.name
                  }}</span>
                  {{ manuData[item.key] || "/" }}
                </div>
              </div>
            </div>
          </div>
          <!-- 标签信息 -->
          <div class="label-message-box">
            <div class="title">
              标签信息<span class="hint" v-if="$route.query.steured == 'false'"
                >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
              >
            </div>
            <div class="label-message">
              <el-descriptions :column="2">
                <el-descriptions-item
                  v-for="(item, index) in label"
                  :key="index"
                >
                  <span class="brief">{{ item.name }}</span>
                  <span v-if="labData[item.key] == 1"> 是 </span>
                  <span v-else-if="labData[item.key] == 0"> 否 </span>
                  <span v-else>/</span>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manufacturerInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //厂家信息
    label: {
      type: Array,
      default: () => {
        return [];
      },
    }, //标签信息,
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //数据商品基本
    imgDate: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //厂家信息
    labData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //标签信息
    chagepic: {
      type: Boolean,
      default: () => {
        return null;
      },
    }, //商品基本信息
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      showCategoryArr:false
    };
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    width: 48%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        // border: 1px solid #797979;
        // margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;

      .vender-message {
        // border: 1px solid #797979;
        padding: 10px;
        width: 100%;
        display: flex;
        font-size: 14px;
        line-height: 24px;
        color: #333;
        flex-wrap: wrap;
        .all {
          width: 50%;
        }
        .half {
          width: 50%;
        }
        span {
          color: #666;
          margin-right: 44px;
          width: 112px;
          display: inline-block;
          text-align: end;
          line-height: 40px;
        }
        .brief {
          width: 60px;
        }
      }
    }
    .label-message {
      width: 100%;
      // border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.brief {
  width: 60px !important;
  display: inline-block;
  margin-right: 44px;
  line-height: 40px;
}
.categoryArr-box{
  flex: 1;
  position: relative !important;
  // top: 10;
}
.center-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  line-height: 36px;
  padding: 20px;
  font-size: 14px;
  color: #333;
  > div {
    width: 50%;
    span {
      color: #666;
      width: 100px !important;
      text-align: end;
      display: inline-block;
      margin-right: 20px !important;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #00bfbf;
      white-space:nowrap;
    }
    .noend {
      text-align: start;
    }
  }
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}
.categoryArr {
  display: flex;
  span {
    width: auto !important;
    margin-right: 0px !important;
  }
  .categoryArr-name {
    color: #06b7ae;
  }
  .categoryArr-title {
    width: 100px !important;
    margin-right: 20px !important;
    font-size: 14px;
  }
}
::v-deep .el-descriptions-item__label {
  width: 0px !important;
  justify-content: start !important;
  margin-right: 0px;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}

.lang-select-dropdown {
  position: absolute !important;
  top: 36px !important;
  left: -10px !important;
  z-index: 2077;
  background-color: #fff;
  white-space:nowrap;
  box-shadow:2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 0 10px;
  font-size: 14px;
}
</style>
