<template>
  <div>
    <div class="merch-box" v-for="(item, index) in qualify" :key="index">
      <div class="title">
        {{ item.name
        }}<span class="hint" v-if="$route.query.steured=='false'"
          >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
        >
      </div>
      <div class="center-box">
        <template v-if="qualifyData.length > 0">
          <div
            class="img-box"
            v-for="(elem, index) in qualifyData"
            :key="index"
          >
            <div class="img-title exceed" :title="elem.qualifyname">
              {{ elem.qualifyName || "无" }}
            </div>
            <div class="rela">
              <el-image
                style="width: 120px; height: 120px; border-radius: 10px"
                :src="elem.qualifyUrl"
                :preview-src-list="[elem.qualifyUrl]"
              >
              </el-image>
              <!-- <div class="absoimg">点击放大</div> -->
            </div>
          </div>
        </template>
        <template v-else> 未上传产品资质 </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qualify: {
      type: Array,
      default: () => {
        return [];
      },
    }, //资质配置
    qualifyData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(10, 10%);
    font-size: 12px;
    .img-box {
      // margin-right: 10px;
      width: 100%;
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .img-title {
        width: 100px;
        font-size: 14px;
        // text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}

</style>
